import Vue from 'vue';

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';  // Import ToastService
import Toast from 'primevue/toast';  // Import Toast component
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

Vue.use(ToastService);  // Install ToastService globally
Vue.use(PrimeVue);  // Install PrimeVue
Vue.component('Toast', Toast);  // Globally register Toast component